import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import logo from '../assets/images/logo.png';
import bg from '../assets/images/login-bg.png';

import upArrow from '../assets/svg/up.svg';
import downArrow from '../assets/svg/down.svg';

import editIcon from '../assets/svg/edit.svg';
import image from '../assets/svg/image.svg';

import PageForm from '../components/forms/pageForm';
import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

class CompetitionPreviewPage extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {
        if (!localStorage.token){
            return;
        }

        if (this.props[0].match.params.id) {
            fetch('https://dpsklas.novamedia.agency/admin/fetchOne/competition/' + this.props[0].match.params.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`

                }
            }).then((res) => res.json()).then((result) => {
                console.log(result);
                this.setState(
                    result
                )
            })

        }

    }

    render() {

        return (
            <div className="page-wrap">
                {
                    !localStorage.token ? <Redirect to='/login' /> : null
                }

                <Container fluid>

                    <Row className="page-title">
                        <Col lg="12">
                            <h3>Prijava</h3>
                        </Col>

                    </Row>

                    <Row>
                        <Col lg="12" >
                            <Container fluid className="form-box contact-box">
                                <Row>
                                    <Col lg="3">
                                        <label>IME</label>
                                        <p>{this.state.name}</p>
                                    </Col>
                                    <Col lg="3">
                                        <label>TELEFON</label>
                                        <p>{this.state.phoneNumber}</p>
                                    </Col>

                                    <Col lg="3">
                                        <label>E-MAIL</label>
                                        <p>{this.state.email}</p>
                                    </Col>
                                    <Col lg="3">
                                        <label>ŽELJENA POZICIJA</label>
                                        <p>{this.state.position}</p>
                                    </Col>

                                    <Col lg="12" className="spacer">
                                        <div></div>
                                    </Col>
                                    <Col lg="12">
                                        <h3 className="title">Poruka</h3>
                                        <p className="content">
                                            <p>{this.state.message}</p>

                                        </p>
                                    </Col>
                                  {this.state.cv?  <Col lg="12">
                                    <a href={this.state.cv}>
                                    <button className="button">Pogledaj CV</button>
                                    </a>
                                    </Col>:null}
                                </Row>
                            </Container>
                        </Col>
                    </Row>

                </Container>


            </div>
        );
    }
}

export default Page(CompetitionPreviewPage);
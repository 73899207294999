import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Isvg from 'react-inlinesvg';

import Text from './fields/text';
import Textarea from './fields/textarea';
import HtmlImage from './fields/htmlImage';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Textarea
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )




const renderHtmlImageField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <HtmlImage
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )



const form = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col lg="6" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">Socijalne mreže</h3>
                                <h6 className="subtitle">Unesite linkove ka socijalnim mrežama</h6>

                            </Col>
                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="facebook"
                                    component={renderTextField}
                                    label={"Facebook"}
                                    placeholder="Unesite link facebook stranice"

                                ></Field>

                            </Col>
                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="twitter"
                                    component={renderTextField}
                                    label={"Twitter"}
                                    placeholder="Unesite link twitter stranice"

                                ></Field>

                            </Col>

                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="instagram"
                                    component={renderTextField}
                                    label={"Instagram"}
                                    placeholder="Unesite link instagram stranice"

                                ></Field>

                            </Col>
                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="linkedin"
                                    component={renderTextField}
                                    label={"LinkedIn"}
                                    placeholder="Unesite link linkedin stranice"

                                ></Field>

                            </Col>
                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="youtube"
                                    component={renderTextField}
                                    label={"YouTube"}
                                    placeholder="Unesite link youtube kanala"

                                ></Field>

                            </Col>

                        </Row>
                    </Container>
                </Col>

                <Col lg="6" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">Informacije za inostrane pošiljke</h3>
                                <h6 className="subtitle">Informacije o inostranim pošiljkama možete dobiti pozivom na brojeve:</h6>

                            </Col>
                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="foreignContact[0].city"
                                    component={renderTextField}
                                    label={"Grad"}
                                    placeholder="Unesite ime grada"

                                ></Field>

                            </Col>

                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="foreignContact[0].telephone"
                                    component={renderTextField}
                                    label={"Telefon"}
                                    placeholder="Unesite telefon"

                                ></Field>

                            </Col>


                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="foreignContact[0].email"
                                    component={renderTextField}
                                    label={"E-mail"}
                                    placeholder="Unesite e-mail"

                                ></Field>

                            </Col>

                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="foreignContact[1].city"
                                    component={renderTextField}
                                    label={"Grad"}
                                    placeholder="Unesite ime grada"

                                ></Field>

                            </Col>

                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="foreignContact[1].telephone"
                                    component={renderTextField}
                                    label={"Telefon"}
                                    placeholder="Unesite telefon"

                                ></Field>

                            </Col>


                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="foreignContact[1].email"
                                    component={renderTextField}
                                    label={"E-mail"}
                                    placeholder="Unesite e-mail"

                                ></Field>

                            </Col>

                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="foreignContact[2].city"
                                    component={renderTextField}
                                    label={"Grad"}
                                    placeholder="Unesite ime grada"

                                ></Field>

                            </Col>

                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="foreignContact[2].telephone"
                                    component={renderTextField}
                                    label={"Telefon"}
                                    placeholder="Unesite telefon"

                                ></Field>

                            </Col>


                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="foreignContact[2].email"
                                    component={renderTextField}
                                    label={"E-mail"}
                                    placeholder="Unesite e-mail"

                                ></Field>

                            </Col>


                        </Row>
                    </Container>
                </Col>

                <Col lg="12">
                    <button className="button">Save</button>

                </Col>

            </Row>

        </form>
    )
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
